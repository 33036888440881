import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../../config/routing'
import { formatDateTime } from '../../modules/moment'
import { getRoomWithLatestTalk } from '../../datastores/room'
import './roomListItem.scss'
import { exitRoom } from '../../datastores/user'
import { createTalk } from '../../datastores/talk'
import { deleteTalkRoom } from '../../modules/indexedDB'
import { useSwipeable } from 'react-swipeable'
import { deleteFCMToken } from '../../datastores/fcmToken'

export default function RoomListItem(props) {
  const { userId, roomId, showControl, showControlItemId, setShowControlItemId } = props
  const [room, setRoom] = useState(undefined)
  const [latestTalk, setLatestTalk] = useState([])
  const [unread, setUnread] = useState(false)
  const [hasDeleted, setHasDeleted] = useState(false)
  const mouseMovedRef = useRef(false);
  const wrapperRef = useRef(null);

  const swipeHandlers = useSwipeable({
    delta: { left: 5, right: 5 },
    trackMouse: true,
    onSwiping: ({ deltaX }) => {
      if (wrapperRef.current) {
        const newRight = parseInt(getComputedStyle(wrapperRef.current).right) - deltaX;
        wrapperRef.current.style.right = Math.min(Math.max(newRight, 0), 80) + 'px';
      }
    },
    onSwiped: (eventData) => {
      if (wrapperRef.current) wrapperRef.current.style.right = '';
      if (eventData.dir === 'Left') setShowControlItemId(userId);
      else if (showControlItemId === userId) setShowControlItemId('');
    },
  })

  useEffect(() => {
    const fetchData = async () => {
      const result = await getRoomWithLatestTalk(roomId, userId)
      setRoom(result.room)
      setLatestTalk(result.latest)
      setUnread(result.unread)
    }
    
    fetchData();
  }, [])

  async function exitRoomAction(userId) {
    const result = window.confirm('このトークルームから退室してよろしいですか？')
    if (!result) return

    await deleteFCMToken(userId, roomId)
    const updatedUser = await exitRoom(userId)
    const contents = `${updatedUser.nickname}さんが退室しました。`;
    await createTalk(updatedUser.id, roomId, contents, null, null, 'info').then((res) => {
    }).catch((err) => {
      // TODO ログ出力
      throw err
    })
    await deleteTalkRoom(userId)
    setHasDeleted(true)
  }

  return room !== undefined && !hasDeleted && (
    <div
      className={"roomListItemWrapper" + (showControl ? ' roomListItemWrapper--showControl': '')}
      {...swipeHandlers}
      ref={(node) => {
        swipeHandlers.ref(node);
        wrapperRef.current = node;
      }}
    >
      <Link
        to={`${routes.room}/${userId}`}
        className="roomListItem"
        onMouseDown={e => {e.preventDefault(); mouseMovedRef.current = false}}
        onMouseMove={e => {mouseMovedRef.current = true}}
        onClick={e => {
          if ('ontouchstart' in window || navigator.maxTouchPoints) return; // タッチデバイスは除外
          if (mouseMovedRef.current) e.preventDefault();
        }}
      >
        <p className="name">{room.name}</p>
        <div className="rightContent">
          {latestTalk &&
            <>
              <p className="lastDate">{formatDateTime(latestTalk.sendAt)}</p>
              {unread &&
                <p className="unread">未読</p>
              }
            </>
          }
        </div>
      </Link>
      <button onClick={() => exitRoomAction(userId)} className="exitRoomButton">削除</button>
    </div>
  )
}
