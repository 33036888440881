const shareMessage = 'Txtbase（テキストベース）は、使い捨てできる無料のメッセンジャー'
const TXTBASE_URL = 'https://txtbase.net'
export const messages = {
  mvText: {
    en: 'Welcome!',
  },
  errors: {
    error: { image: '/images/error.svg', text: "Something went wrong. Sorry..." },
    notFound: { image: '/images/404.svg', text: 'That page is gone.' },
  },
  inviteCodeError: '無効な招待コードです。',
  shareMessage: shareMessage,
  features: [
    { text: 'アカウント不要', src: '/images/img_first.svg' },
    { text: 'アプリインストール不要', src: '/images/img_second.svg' },
    { text: 'メッセージ暗号化', src: '/images/img_third.svg' },
    // { text: '自動翻訳', src: '/images/img_forth.svg' },
  ],
  shares: [
    { url: `https://social-plugins.line.me/lineit/share?text=${encodeURI(shareMessage + '\n')}&url=${encodeURI(TXTBASE_URL)}`, src:'/images/icon_line.svg', text: 'LINE'},
    { url: `https://x.com/intent/post?&text=${encodeURI(shareMessage + '\n')}&url=${encodeURI(TXTBASE_URL)}`, src:'/images/icon_x.svg', text: 'X'},
    { url: 'mailto:?subject=Txtbase%E3%82%92%E4%BD%BF%E3%81%84%E3%81%BE%E3%81%9B%E3%82%93%E3%81%8B%EF%BC%9F&body=Txtbase%EF%BC%88%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E3%83%99%E3%83%BC%E3%82%B9%EF%BC%89%E3%81%AF%E3%80%8C%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E4%B8%8D%E8%A6%81%E3%80%8D%E3%80%8C%E3%82%A4%E3%83%B3%E3%82%B9%E3%83%88%E3%83%BC%E3%83%AB%E4%B8%8D%E8%A6%81%E3%80%8D%E3%80%8C%E3%83%A1%E3%83%83%E3%82%BB%E3%83%BC%E3%82%B8%E8%87%AA%E5%8B%95%E5%89%8A%E9%99%A4%E3%80%8D%E3%81%8C%E7%89%B9%E5%BE%B4%E3%81%AE%E3%83%A1%E3%83%83%E3%82%BB%E3%83%B3%E3%82%B8%E3%83%A3%E3%83%BC%E3%81%A7%E3%81%99%E3%80%82%E7%84%A1%E6%96%99%E3%81%A7%E4%BD%BF%E3%81%88%E3%82%8B%E3%81%AE%E3%81%A7%E3%80%81%E3%81%8A%E6%B0%97%E8%BB%BD%E3%81%AB%E3%81%94%E5%88%A9%E7%94%A8%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%E3%80%82https%3A%2F%2Ftxtbase.net%2F', src:'/images/icon_e-mail.svg', text: 'E-mail'},
    { url: '', src:'/images/icon_share.svg', text: 'More'},
  ],
  faq: [
    { q: '料金はかかりますか？', a: 'いいえ、無料です。' },
    { q: 'プッシュ通知はどうすれば受け取れますか？',
      a: 'ブラウザの「ホーム画面に追加」を行うと、プッシュ通知を受け取れるようになります。ただし、OS等のバージョンが古い場合はご利用いただけないことがあります。また、通知設定をオフにしている場合は、ご自身で設定を変更してください。' },
    { q: '複数のトークを管理するにはどうすればよいですか？',
      a: 'トーク履歴で管理できます。ただし、ブラウザのキャッシュクリアなどで履歴がなくなることがあるので、トークごとにURLをブックマークしておくと安全です。' },
    { q: 'トーク履歴が消えた場合、復元することはできますか？',
      a: 'トークのURLがわからない場合は、復元できません。もしブックマークしているトークURLがある場合は、アクセスすると再びトーク履歴に追加されます。' },  
  ],
  addToHome: [
    { copy: 'ホーム画面に追加する',
      subCopy: '追加すると通知を受け取れます' },
  ],
  room: {
    title: 'トークルームタイトル',
    error: {
      invalidPasscord: 'パスコードが違います',
    }
  },
  userSetting: { // 初回アクセス時の設定と共有
    nickname: 'あなたの名前',
    usePass: 'パスコード',
    passcord: '設定するパスコード',
    passcordConfirm: '設定するパスコード（確認）',
    currentPasscord: '現在のパスコード',
    newPasscord: '設定するパスコード',
    confirmPasscord: '設定するパスコード（確認）',
    usePush: 'プッシュ通知',
    error: {
      nickname: 'ニックネームを入力してください',
      passcord: 'パスコードを入力してください',
      passcordConfirm: '確認のためにもう一度入力してください',
      confirm: 'パスコードが一致しません',
      currentPasscord: '現在のパスコードを入力してください',
      invalidCurrentPasscord: '現在のパスコードが違います',
      newPasscord: '新しいパスコードを入力してください',
      confirmPasscord: '確認のためにもう一度入力してください',
      updateConfirm: 'パスコードが一致しません',
    },
  },
}
