import React, { useState } from 'react'
import './headerRoomList.scss'
import { Image } from '@aws-amplify/ui-react'
import InputBorderWhite from '../atoms/InputBorderWhite'
import { config } from '../../config/config'
import ButtonBlack from '../atoms/ButtonBlack'
import { useNavigate } from 'react-router-dom'

export default function HeaderRoomList(props) {
  const [showPopup, setShowPopup] = useState(false)
  const [talkUrl, setTalkUrl] = useState('')
  const [talkUrlError, setTalkUrlError] = useState('')
  const navigate = useNavigate()
  
  const enterFromUrl = (e) => {
    e.preventDefault()
    navigate(talkUrl.replace(config.siteUrl, ''))
  }

  const handleUrlChange = (url) => {
    setTalkUrl(url);

    const isValid = new RegExp(`^${config.siteUrl}/room/.+`).test(url)
    setTalkUrlError(url && !isValid ? '無効なURLです' : '')
  }
  
  return (
    <>
      <header className="headerRoomList">
        <div className="roomListTitle">
          トーク履歴
        </div>
        {/* TODO 検索フォームの実装 */}
        <button className="addRoomButton" onClick={() => setShowPopup(true)}>
          <Image src='/images/icon_plus.svg' />
        </button>
        {showPopup && (
          <div className="popup">
            <div className="popup-content">
              <div className="close-btn" onClick={() => setShowPopup(false)}></div>
              <p>URLからトークを再開する</p>
              <form onSubmit={enterFromUrl}>
                <InputBorderWhite
                  value={talkUrl}
                  changeAction={handleUrlChange}
                  placeholder={config.siteUrl + '/room/xxxxx'}
                  error={talkUrlError}
                />
                <ButtonBlack
                  type='submit'
                  value='追加'
                  disabled={!talkUrl || talkUrlError}
                  className={'button'}
                />
              </form>
            </div>
          </div>
        )}
      </header>
    </>
  )
}
