import React, { useEffect, useState } from 'react'
import './userIconInput.scss'
import TxtFormError from './TxtFormError'
import { Image } from '@aws-amplify/ui-react'
import axios from 'axios'
import { config } from '../../config/config'
import imageCompression from 'browser-image-compression';
import { updateUser } from '../../datastores/user'

export default function UserIconInput(props) {
  const { value='', error=undefined, roomId, user, setUserAction, setIsProcessing=()=>{} } = props
  const [imageFile, setImageFile] = useState(null)
  const [previewUrl, setPreviewUrl] = useState(user.iconUrl)

  const handleFileChange = async (e) => {
    const file = e.target.files?.[0]
    if (!file && file.type?.startsWith('image/')) return;

    setIsProcessing(true);

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 960,
      useWebWorker: true
    };
    const compressedBlob = await imageCompression(file, options);
    const compressedFile = new File([compressedBlob], file.name, { type: 'image/jpeg', lastModified: Date.now() });

    setImageFile(compressedFile)
  }

  useEffect(() => {
    if (!imageFile) return;

    let reader = new FileReader()
    reader.onloadend = () => {
			// base64のimageUrlを生成する。
      const base64 = reader?.result;
      if (base64 && typeof base64 === 'string') {
        setPreviewUrl(base64);
      }
    }
    reader.readAsDataURL(imageFile)

    uploadFileAndUpdateUser(roomId, user, imageFile)
    .then((updatedUser) => {
      setTimeout(() => {
        setUserAction(updatedUser);
      }, 1000)
    }).catch((error) => {
      console.log(error)
      setPreviewUrl('');
    }).finally(() => {
      setImageFile(null);
      setIsProcessing(false);
    })
  
    return () => {
      reader = null;
    }
  }, [imageFile])

  const iconUrl = previewUrl || user.iconUrl;
  
  return (
    <label className="userIconInput">
      <div className="userIconInput__preview">
        {iconUrl
          ? <Image src={iconUrl} width="100px" height="100px" objectFit="cover" borderRadius="50%" overflow="clip" />
          : <Image src='/images/icon_profile_white.svg' />}
        <Image src='/images/profile_photo.svg' className="userIconInput__photoIcon" />
      </div>
      <input className='inputBlack'
        type="file"
        accept="image/*"
        value={value}
        hidden
        onChange={handleFileChange}
      />
      {error &&
        <TxtFormError error={error} />
      }
    </label>
  )
}


const uploadFileAndUpdateUser = async (roomId, user, imageFile) => {
  const data = new FormData()
  data.append('type', 'user_icon')
  data.append('roomId', roomId)
  data.append('userId', user.id)
  data.append('timestamp', '')
  data.append(`files_0`, imageFile)

  const res = await axios.post(
    config.fileUploadApi,
    data,
    { headers: { 'Content-Type': 'multipart/form-data' }}
  )

  console.log(res)

  const imagePath = res.data.path;
  return updateUser(
    {...user, icon: imagePath},
    user.nickname,
    user.hasPassword,
    user.password,
    user.pushNotification
  );
}
