import axios from 'axios'
import React, { useState } from 'react'
import moment from 'moment'
import { Image } from '@aws-amplify/ui-react'
import imageCompression from 'browser-image-compression';
import { config } from '../../config/config'
import { createTalk } from '../../datastores/talk'
import { formatDateTimeForPrefix } from '../../modules/moment'
import './inputTalk.scss'

export default function InputTalk(props) {
  const { userId, roomId } = props
  const [contents, setContents] = useState('')
  const [files, setFiles] = useState([])
  const timestamp = formatDateTimeForPrefix(moment())
  const [tempContents, setTempContents] = useState('')

  const changeFile = async (tempFiles) => {
    let compressedFiles = [];
    for (let file of tempFiles) {
      // Check file size for non-image files
      if (!file.type.startsWith('image/') && file.size > 1000000) {
        alert("File size should be less than 1MB");
        continue;
      }

      if (file.type && file.type.startsWith('image/')) {
        const options = {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 960,
          useWebWorker: true
        };
        const compressedBlob = await imageCompression(file, options);
        const compressedFile = new File([compressedBlob], file.name, { type: 'image/jpeg', lastModified: Date.now() });
        compressedFiles.push(compressedFile);
      } else {
        compressedFiles.push(file);
      }
    }
    setFiles(compressedFiles);
    setPreviewImage(compressedFiles);
  }

  const clickRemove = (e) => {
    const removeButtonList = Array.from(document.getElementsByClassName('removeButton'))
    const index = removeButtonList.findIndex(item => item === e.target)

    const newFiles = files.filter((value, i) => i !== index)
    setFiles(newFiles)
    setPreviewImage(newFiles)

    document.getElementById('talkFile').value = null
  }

  const setPreviewImage = (tempFiles) => {
    const imageList = tempFiles.filter(f => f.type.indexOf('image') !== -1)
    for (let i = 0; i < imageList.length; i++) {
      const fileReader = new FileReader()
      fileReader.onload = (() => {
        document.getElementsByClassName('previewFileItemImage')[i].src = fileReader.result
      })
      fileReader.readAsDataURL(imageList[i])
    }
  }

  const sendTalkAction = (e) => {
    e.preventDefault();
    if (contents.trim() === '' && files.length === 0) return;

    let formattedContents = contents.trim() === '' ? null : formatParagraphs(contents);

    let dataList = [];
    if (formattedContents !== null) {
      dataList.push({ type: 'contents', data: formattedContents });
    }

    if (files.length !== 0) {
      axios.post(
        config.fileUploadApi,
        createFileUploadData(),
        { headers: { 'Content-Type': 'multipart/form-data' }}
      ).then((res) => {
        console.log(res);

        setTimeout(() => {
          files.forEach(
            (file) => dataList.push({ type: 'files', path: res.data.path, fileType: file.type })
          );

          createTalkFunction(dataList).then(() => {
            // ...
          }).catch((err) => {
            // ...
          }).finally(() => {
            setContents('');
            setFiles([]);
            initInputHeight(e);
          });
        }, 1000); 
      });
    } else {
      createTalkFunction(dataList).then(() => {
        // ...
      }).catch((err) => {
        // ...
      }).finally(() => {
        setContents('');
        setFiles([]);
        initInputHeight(e);
      });
    }
  }

  const createFileUploadData = () => {
    const res = new FormData()

    res.append('roomId', roomId)
    res.append('userId', userId)
    res.append('timestamp', timestamp)

    for (let i = 0; i < files.length; i++) {
      res.append(`files_${i}`, files[i])
    }

    return res
  }

  const createTalkFunction = async (dataList) => {
    dataList.forEach((d) => {
      const contents = d.type === 'files'? null : d.data
      const path = d.type === 'files'? d.path : null
      const fileType = d.type === 'files'? d.fileType : null

      // TODO 送信失敗時にすべてロールバックするか？
      createTalk(userId, roomId, contents, path, fileType).then((res) => {
      }).catch((err) => {
        // TODO ログ出力
        throw err
      })
    })
  }

  const sendTalkShortCut = (e) => {
    const isCtrlOrCmd = e.ctrlKey || e.metaKey;

    if (e.key === 'Enter' && isCtrlOrCmd) {
      e.preventDefault();
      document.getElementById('sendTalkButton').click();
    }
  }

  const initInputHeight = (e) => {
    const standardInputHeight = 40;
    document.querySelector('.inputMessage textarea')
      .style.height = `${standardInputHeight}px`
  }
  const setNewInputHeight = (e, oldRoomAreaScrollBottom) => {
    const maxHeight = Math.floor((window.innerHeight - document.querySelector('.headerRoom').clientHeight) / 2)
      - (document.querySelector('.previewFile')?.clientHeight ?? 0);
    const newHeight = maxHeight > e.target.scrollHeight ? e.target.scrollHeight : maxHeight;
    e.target.style.height = newHeight + 'px';

    // カーソルが最終行にある時は終端までスクロールさせる（padding-bottom分まで自動でスクロールされない問題の対処）
    const isCursorOnLastLine = !e.target.value.slice(e.target.selectionStart).includes('\n');
    if (isCursorOnLastLine) e.target.scrollTop = e.target.scrollHeight;

    // トークエリアのスクロール位置を下部を基準に一定に保つ
    const areaWrapper = document.querySelector('.roomAreaWrapper');
    areaWrapper.scrollTop = oldRoomAreaScrollBottom - areaWrapper.clientHeight;
  }
  const changeInputHeight = (e) => {
    // 元のトークエリア下部のスクロール位置を保存
    const areaWrapper = document.querySelector('.roomAreaWrapper');
    const oldRoomAreaScrollBottom = areaWrapper.scrollTop + areaWrapper.clientHeight;
    // 一度基準の高さに初期化
    initInputHeight(e);
    // 新たな高さに変更
    setNewInputHeight(e, oldRoomAreaScrollBottom);
  }

  const formatParagraphs = (text) => {
    const paragraphs = text.split('\n');
    return paragraphs.map((paragraph, index) => {
      if (paragraph.trim() === '') {
        return '\u00A0';
      } else {
        return paragraph;
      }
    }).join('\n');
  }

  return (
    <footer className="footerInputMessage">
      <form id="inputMessage" className="inputMessage" onSubmit={sendTalkAction} encType="multipart/form-data">
        <label className="actionBtn">
          <Image src='/images/icon_attach_file.svg' />
          <input type="file" id="talkFile" hidden multiple onChange={(e) => changeFile(e.target.files)} />
        </label>
        <textarea 
          placeholder='ここにメッセージを入力' 
          value={contents} 
          onChange={(e) => setContents(e.target.value)} 
          onBlur={(e) => {
            if (e.target.value.trim() !== '') {
              setContents(formatParagraphs(e.target.value));
            }
          }}
          onKeyDown={(e) => sendTalkShortCut(e)} 
          onInput={(e) => changeInputHeight(e)}
        />

        <button id="sendTalkButton" type='submit' className="sendBtn">
          <Image src='/images/icon_send.svg' />
        </button>

        {files.length !== 0 &&
          <div className="previewFile">
            {files.map((f, i) => {
              if (f.type.indexOf('image') !== -1)
                return (
                  <div key={i} className="previewFileItem">
                    <Image className="removeButton" src="/images/icon_close.png" onClick={(e) => clickRemove(e)} />
                    <Image className="previewFileItemImage" src="" />
                  </div>
                )

              return (
                <div key={i} className="previewFileItem else">
                  <Image className="removeButton" src="/images/icon_close.png" onClick={(e) => clickRemove(e)} />
                  <Image className="fileIcon" src="/images/icon_file.png" />
                  <p>{f.name}</p>
                </div>
              )
            })}
          </div>
        }
      </form>
    </footer>
  )
}
