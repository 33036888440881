import { DataStore } from 'aws-amplify'
import { FCMToken } from '../models'
import { getNotificationToken } from '../firebase'

export const createToken = async (userId, roomId, fcmToken) => {
  return await DataStore.save(
    new FCMToken({
      userID: userId,
      roomID: roomId,
      fcmToken: fcmToken
    })
  )
}

export const getItem = async (userId, roomId, tokenId) => {
  try {
    const registeredToken = await DataStore.query(FCMToken, c => c.fcmToken("eq", tokenId).userID("eq", userId).roomID("eq", roomId));
    return registeredToken
  } catch (err) {
    console.log("[ERROR] Error query record by id:", err)
    return [];
  }
}

export const deleteFCMToken = async (userId, roomId) => {
  try {
    const currentToken = await getNotificationToken()
    if (!currentToken)  return;
    
    const totalItems = await getItem(userId, roomId, currentToken)
    
    for (const item of totalItems)
      await DataStore.delete(item)
  }catch(e) {
    console.log("Something wrong happened in token deletion", e)
  }
}
