import React from 'react'
import { Link } from '@aws-amplify/ui-react'
import { messages } from '../../config/message'
import FeatureImage from '../molecules/FeatureImage'
import './features.scss'

export default function Features(props) {
  return (
    <div className="featuresArea">
      <h2>
        トークアプリのように使えて、<br />
        使い捨てできる。
      </h2>
      {messages.features.map((f, i) =>
        <FeatureImage key={i} text={f.text} src={f.src} />
      )}
    </div>
  )
}